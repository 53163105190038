import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        
        <div className="addbg2">
          <div className="wrapper">
            <header>
              <h1 className="logo">
                <a href="/">Neutrogena</a>
              </h1>
              <nav>
                <a href="/produkty/" className="link">
                  Produkty
                </a>
                <span>| </span>
                <a href="/neutrogena/o-nas/" className="link">
                  NEUTROGENA<sup>®</sup>
                </a>
                <span>| </span>
                <a href="/budte-krasna/" className="link">
                  Buďte krásná
                </a>
                <span>| </span>
                <a href="/nordic-berry/" className="link">
                  Nordic Berry
                </a>
                <span />
                <span>|</span>
                <a href="/plet-s-nedokonalostmi/">Pleť s nedokonalostmi</a>
                <span />
              </nav>
            </header>
            <div className="content">
              <div className="breadcrumb">
                <a href="/">
                  NEUTROGENA<sup>®</sup>
                </a>
                <span>&gt;</span>
                <a href="/produkty/">Popis</a>
                <span>&gt;</span>
                <a href="/product/81/#" className="blue">
                  {" "}
                  <p>
                    NEUTROGENA<sup>®</sup>&nbsp;VISIBLY CLEAR<sup>®</sup>
                    &nbsp;&nbsp;Spot Proofing™&nbsp;Micelární čisticí tonikum
                  </p>{" "}
                </a>
              </div>
              <div className="singleproduct">
                <div className="prodimgcont">
                  <div className="bot">
                    <img
                      src="/assets/Uploads/NEUTROGENA-spot-proofing-purifying-lotion.jpg"
                      alt="NEUTROGENA spot proofing purifying lotion"
                    />
                  </div>
                </div>
                <div className="info">
                  <h2>
                    {" "}
                    <p>
                      NEUTROGENA<sup>®</sup>&nbsp;VISIBLY CLEAR<sup>®</sup>
                      &nbsp;&nbsp;Spot Proofing™&nbsp;Micelární čisticí tonikum
                    </p>
                  </h2>
                  <dl>
                    <dt>Popis:</dt>
                    <dd>
                      <p>
                        Pomáhá čistit pupínky a odstraňovat nečistoty, špínu a
                        maz, čímž předchází tvorbě nových pupínků.
                      </p>
                      <p>
                        <br />
                        Složení s ClearDefend™ technologií:
                        <br />
                        1. Čistící pleťová voda pomáhá bojovat proti pupínkům
                        <br />
                        2. Zanechává většinu základních živin pokožky
                        neporušených
                      </p>
                      <p>
                        <br />
                        Používejte po vyčištění pleti jako součást každodenní
                        péče pro dokonale čistou pleť.
                        <br />
                        Složení obsahující micelární částice účinně čistí a
                        odstraňuje nečistoty, aniž by došlo k vysoušení pokožky.
                      </p>
                      <p>
                        <span>
                          <span>&nbsp;</span>
                        </span>
                      </p>
                    </dd>
                  </dl>
                  <dl>
                    <dt>Použití:</dt>
                    <dd>
                      Používejte dvakrát denně. Aplikujte na vatový tampon a
                      jemně masírujte obličej pro odstranění nečistot. Zabraňte
                      kontaktu s očima. V případě vniknutí do očí důkladně
                      vypláchněte vodou. Pouze pro vnější použití.
                    </dd>
                  </dl>
                  <dl className="last">
                    <dt>Balení:</dt>
                    <dd>200 ml</dd>
                  </dl>
                </div>
                <div id="fb_37"></div>
              </div>
              <div className="products">
                <div className="row">
                  <div className="rowtitle">Zobrazit související produkty:</div>
                  <a
                    className="product group1_1 group2_1 group3_1"
                    href="/product/94/"
                  >
                    <img
                      src="/assets/Uploads/NEUTROGENA-HydroBoost-hydratacni-pleovy-gel-50ml-normalni-ple-500x.png"
                      alt="NEUTROGENA HydroBoost hydratacni pleovy gel 50ml normalni ple 500x"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>® </sup>Hydro&nbsp;Boost<sup>®</sup>{" "}
                        Hydratační pleťový gel - normální až smíšená pleť
                        <sup>
                          <br />
                        </sup>
                      </p>
                    </span>
                    <span className="button">Podrobnosti</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_1 group2_1 group3_1"
                    href="/product/95/"
                  >
                    <img
                      src="/assets/Uploads/Neutrogena-HB-City-Shield-krem-SPF25-500x2.png"
                      alt="Neutrogena HB City Shield krem SPF25 500x2"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>® </sup>Hydro&nbsp;Boost<sup>®</sup> City
                        Shield hydratační krém s SPF 25
                      </p>
                      <p>&nbsp;</p>
                    </span>
                    <span className="button">Podrobnosti</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_1 group2_1 group3_1"
                    href="/product/97/"
                  >
                    <img
                      src="/assets/Uploads/NEUTROGENA-HydroBoost-peeling-150ml-500x2.png"
                      alt="NEUTROGENA HydroBoost peeling 150ml 500x2"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>® </sup>Hydro&nbsp;Boost<sup>®</sup>{" "}
                        Vyhlazující pleťový peeling
                      </p>
                    </span>
                    <span className="button">Podrobnosti</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_1 group2_1 group3_1"
                    href="/product/98/"
                  >
                    <img
                      src="/assets/Uploads/NEUTROGENA-HydroBoost-maska-na-noc-50ml-500x.png"
                      alt="NEUTROGENA HydroBoost maska na noc 50ml 500x"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>® </sup>Hydro&nbsp;Boost<sup>®</sup>{" "}
                        Hydratační noční maska
                      </p>
                    </span>
                    <span className="button">Podrobnosti</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_1 group2_1 group3_1"
                    href="/product/99/"
                  >
                    <img
                      src="/assets/Uploads/neutrogena-serum.png"
                      alt="neutrogena serum"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>® </sup>Hydro&nbsp;Boost<sup>®</sup>{" "}
                        Intenzivní Hydratační Sérum
                      </p>
                    </span>
                    <span className="button">Podrobnosti</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bluebar">
          <div className="bar">
            <div className="wrapper">
              <a href="/neutrogena/o-nas/">
                O ZNAČCE NEUTROGENA<sup>®</sup>
              </a>
              <span>|</span>
              <a href="/neutrogena/norska-receptura/">Norská receptura</a>
              <span>|</span>
              <a href="/neutrogena/historie/">Seznamte se s naší historií</a>
              <span>|</span>
              <a href="/produkty/">
                Zobrazit produkty NEUTROGENA<sup>®</sup>
              </a>
            </div>
          </div>
        </div>
        <Footer></Footer>
        <script
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function () {\n$('a').each(function () {\nvar linkhtml = $(this).html();\nif (linkhtml.indexOf('®') > -1 && linkhtml.indexOf('<sup>®</sup>') == -1) {\n$(this).html(linkhtml.replace(\"®\", \"<sup>®</sup>\"));\n}\n});\n});\n"
          }}
        />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function () {\n$('a').each(function () {\nvar linkhtml = $(this).html();\nif (linkhtml.indexOf('®') > -1 && linkhtml.indexOf('<sup>®</sup>') == -1) {\n$(this).html(linkhtml.replace(\"®\", \"<sup>®</sup>\"));\n}\n});\n});\n"
          }}
        />
        
      </div>
    );
  }
}

export default Page;
